import { PageTitle } from 'components/PageTitle';
import { PropsWithChildren, ReactNode } from 'react';

export interface BaseLayoutProps extends PropsWithChildren {
  aside?: ReactNode;
  title?: string;
}

export const BaseLayout = ({ children, aside, title }: BaseLayoutProps) => (
  <>
    <PageTitle optionalTitle={title} />
    <div
      css={{
        display: 'flex',
      }}
    >
      <div css={{ width: '100%' }}>{children}</div>
      {aside}
    </div>
  </>
);
