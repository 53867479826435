import { useTheme } from '@emotion/react';
import type { NextPage } from 'next';
import { Container } from '@mui/material';
import { AppHeader } from 'components/AppHeader/AppHeader';
import { SearchForm } from 'components/SearchForm';
import { SearchParams, useSearchParams } from 'components/SearchForm/search';
import { useFeatureFlags } from 'components/FeatureFlagsProvider';
import { BaseLayout as BaseLayoutWithoutSideNav } from 'pages/layouts/BaseLayout';
import { BaseLayout as BaseLayoutWithSideNav } from 'pages/layouts/BaseLayoutWithSideNav';

export const Home: NextPage = () => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchHandler = (searchParams: SearchParams) => {
    setSearchParams(searchParams);
  };

  const featureFlags = useFeatureFlags();

  const BaseLayout = featureFlags['show-side-navigation']
    ? BaseLayoutWithSideNav
    : BaseLayoutWithoutSideNav;

  return (
    <BaseLayout>
      {/* TODO: move Container to shared-components */}
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        style={{
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <AppHeader
          subtitle={
            <>
              Find your price advantage on any lane <br />
              and win more customer loads.
            </>
          }
        />

        <div
          css={{
            marginTop: theme.spacing(16),
          }}
        >
          <SearchForm onSearch={searchHandler} initialForm={searchParams} />
        </div>
      </Container>
    </BaseLayout>
  );
};

export default Home;
